const namedColors = {
  paintItBlack: "#292B32",
  blueSuedShoes: "#0072CE",
  mrBlueSky: "#00E7EF",
  whiteWedding: "#FFF",
  purpleRain: "#DA38A3",
  rubyTuesday: "#FD3030",
  prettyInPink: "#DA1884",
  greenOnions: "#00F0B2",
  yellowSubmarine: "#FAEF71",
  mellowYellow: "#F3D69D",
  electricBlue: "#00e7ef",
  orangeCrush: "#e78a30"
};

export default {
  rockIcons: {
    home: {
      heading: namedColors.blueSuedShoes,
      text: namedColors.paintItBlack,
      bg: namedColors.mrBlueSky,
      cta: namedColors.whiteWedding,
      ctaText: namedColors.paintItBlack
    }
  },
  rockHeroes: {
    home: {
      heading: namedColors.blueSuedShoes,
      text: namedColors.paintItBlack,
      bg: namedColors.greenOnions,
      cta: namedColors.whiteWedding,
      ctaText: namedColors.paintItBlack
    }
  },
  miniRockers: {
    home: {
      heading: namedColors.blueSuedShoes,
      text: namedColors.paintItBlack,
      bg: namedColors.yellowSubmarine,
      cta: namedColors.whiteWedding,
      ctaText: namedColors.paintItBlack
    }
  },
  primary: {
    main: namedColors.blueSuedShoes,
    cta: namedColors.mrBlueSky,
    ctaText: namedColors.paintItBlack,
    imageBG: namedColors.greenOnions,
    home: {
      heading: namedColors.greenOnions,
      text: namedColors.whiteWedding,
      bg: namedColors.blueSuedShoes,
      cta: namedColors.whiteWedding,
      ctaText: namedColors.blueSuedShoes
    }
  },
  secondary: {
    main: namedColors.prettyInPink,
    cta: namedColors.prettyInPink,
    ctaText: namedColors.whiteWedding,
    imageBG: namedColors.mrBlueSky,
    home: {
      heading: namedColors.mrBlueSky,
      text: namedColors.whiteWedding,
      bg: namedColors.paintItBlack,
      cta: namedColors.whiteWedding,
      ctaText: namedColors.paintItBlack
    }
  },
  camps: {
    main: namedColors.rubyTuesday,
    cta: namedColors.rubyTuesday,
    ctaText: namedColors.whiteWedding,
    home: {
      text: namedColors.paintItBlack,
      heading: namedColors.rubyTuesday,
      bg: namedColors.yellowSubmarine
    }
  },
  foundation: {
    main: namedColors.purpleRain,
    cta: namedColors.purpleRain,
    ctaText: namedColors.whiteWedding,
    bg: namedColors.mellowYellow,
    imageBG: namedColors.purpleRain,
    home: {
      text: namedColors.paintItBlack,
      heading: namedColors.purpleRain,
      bg: namedColors.whiteWedding
    }
  },
  generic: {
    main: namedColors.blueSuedShoes,
    imageBG: namedColors.mrBlueSky,
    cta: "#78F2EB",
    ctaText: "#25272E"
  },
  sing: {
    main: namedColors.electricBlue,
    imageBG: namedColors.orangeCrush,
    cta: namedColors.orangeCrush,
    ctaText: namedColors.whiteWedding
  },
  careers: {
    cta: namedColors.blueSuedShoes,
    ctaText: namedColors.whiteWedding
  }
};
