export const SITE_HOST = 'rocksteadymusicschool.com';
export const SITE_URL = `https://www.${SITE_HOST}`;
export const FACEBOOK_URL = 'https://www.facebook.com/rocksteadymusicschool';
export const TWITTER_URL = 'https://twitter.com/rsmusicschool';
export const INSTAGRAM_URL = 'https://www.instagram.com/rocksteadymusicschool/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/rocksteady-music-school/';
export const CAREERS_EMAIL = `careers@${SITE_HOST}`;
export const INFO_EMAIL = `info@${SITE_HOST}`;
export const PUPIL_PREMIUM_EMAIL = `pupilpremium@${SITE_HOST}`;
export const SCHOOL_RESOURCES_EMAIL = `schoolresources@${SITE_HOST}`;
export const SCHOOLS_EMAIL = `schools@${SITE_HOST}`;
export const SUPPORT_PHONE = '0330 113 0330';
export const SUPPORT_PHONE_WITH_COUNTRY_CODE = `+44${SUPPORT_PHONE.replace(/\s+/g, '').slice(1)}`;
export const SCHOOLS_PHONE = '0330 113 0401';
export const SCHOOLS_PHONE_WITH_COUNTRY_CODE = `+44${SCHOOLS_PHONE.replace(/\s+/g, '').slice(1)}`;
export const WHATSAPP_NUMBER = '447457402950';
export const SUPPORT_OPEN_CLOSE_TIMES = {
  Monday: ['8am', '8pm'],
  Tuesday: ['8am', '8pm'],
  Wednesday: ['8am', '8pm'],
  Thursday: ['8am', '8pm'],
  Friday: ['8am', '6pm'],
};
export const COUNTY_OPTIONS = [
  {
    "text": "Bedfordshire",
    "value": "Bedfordshire",
    "key": "Bedfordshire"
  },
  {
    "text": "Berkshire",
    "value": "Berkshire",
    "key": "Berkshire"
  },
  {
    "text": "Bristol",
    "value": "Bristol",
    "key": "Bristol"
  },
  {
    "text": "Buckinghamshire",
    "value": "Buckinghamshire",
    "key": "Buckinghamshire"
  },
  {
    "text": "Cambridgeshire",
    "value": "Cambridgeshire",
    "key": "Cambridgeshire"
  },
  {
    "text": "Cheshire",
    "value": "Cheshire",
    "key": "Cheshire"
  },
  {
    "text": "Cornwall",
    "value": "Cornwall",
    "key": "Cornwall"
  },
  {
    "text": "Cumbria",
    "value": "Cumbria",
    "key": "Cumbria"
  },
  {
    "text": "Derbyshire",
    "value": "Derbyshire",
    "key": "Derbyshire"
  },
  {
    "text": "Devon",
    "value": "Devon",
    "key": "Devon"
  },
  {
    "text": "Dorset",
    "value": "Dorset",
    "key": "Dorset"
  },
  {
    "text": "County Durham",
    "value": "County Durham",
    "key": "County Durham"
  },
  {
    "text": "East Riding of Yorkshire",
    "value": "East Riding of Yorkshire",
    "key": "East Riding of Yorkshire"
  },
  {
    "text": "East Sussex",
    "value": "East Sussex",
    "key": "East Sussex"
  },
  {
    "text": "Essex",
    "value": "Essex",
    "key": "Essex"
  },
  {
    "text": "Gloucestershire",
    "value": "Gloucestershire",
    "key": "Gloucestershire"
  },
  {
    "text": "Greater Manchester",
    "value": "Greater Manchester",
    "key": "Greater Manchester"
  },
  {
    "text": "Hampshire",
    "value": "Hampshire",
    "key": "Hampshire"
  },
  {
    "text": "Herefordshire",
    "value": "Herefordshire",
    "key": "Herefordshire"
  },
  {
    "text": "Hertfordshire",
    "value": "Hertfordshire",
    "key": "Hertfordshire"
  },
  {
    "text": "Isle of Wight",
    "value": "Isle of Wight",
    "key": "Isle of Wight"
  },
  {
    "text": "Kent",
    "value": "Kent",
    "key": "Kent"
  },
  {
    "text": "Lancashire",
    "value": "Lancashire",
    "key": "Lancashire"
  },
  {
    "text": "Leicestershire",
    "value": "Leicestershire",
    "key": "Leicestershire"
  },
  {
    "text": "Lincolnshire",
    "value": "Lincolnshire",
    "key": "Lincolnshire"
  },
  {
    "text": "London",
    "value": "London",
    "key": "London"
  },
  {
    "text": "Merseyside",
    "value": "Merseyside",
    "key": "Merseyside"
  },
  {
    "text": "Norfolk",
    "value": "Norfolk",
    "key": "Norfolk"
  },
  {
    "text": "North Yorkshire",
    "value": "North Yorkshire",
    "key": "North Yorkshire"
  },
  {
    "text": "Northamptonshire",
    "value": "Northamptonshire",
    "key": "Northamptonshire"
  },
  {
    "text": "Northumberland",
    "value": "Northumberland",
    "key": "Northumberland"
  },
  {
    "text": "Nottinghamshire",
    "value": "Nottinghamshire",
    "key": "Nottinghamshire"
  },
  {
    "text": "Oxfordshire",
    "value": "Oxfordshire",
    "key": "Oxfordshire"
  },
  {
    "text": "Rutland",
    "value": "Rutland",
    "key": "Rutland"
  },
  {
    "text": "Shropshire",
    "value": "Shropshire",
    "key": "Shropshire"
  },
  {
    "text": "Somerset",
    "value": "Somerset",
    "key": "Somerset"
  },
  {
    "text": "South Yorkshire",
    "value": "South Yorkshire",
    "key": "South Yorkshire"
  },
  {
    "text": "Staffordshire",
    "value": "Staffordshire",
    "key": "Staffordshire"
  },
  {
    "text": "Suffolk",
    "value": "Suffolk",
    "key": "Suffolk"
  },
  {
    "text": "Surrey",
    "value": "Surrey",
    "key": "Surrey"
  },
  {
    "text": "Tyne and Wear",
    "value": "Tyne and Wear",
    "key": "Tyne and Wear"
  },
  {
    "text": "Warwickshire",
    "value": "Warwickshire",
    "key": "Warwickshire"
  },
  {
    "text": "West Midlands",
    "value": "West Midlands",
    "key": "West Midlands"
  },
  {
    "text": "West Sussex",
    "value": "West Sussex",
    "key": "West Sussex"
  },
  {
    "text": "West Yorkshire",
    "value": "West Yorkshire",
    "key": "West Yorkshire"
  },
  {
    "text": "Wiltshire",
    "value": "Wiltshire",
    "key": "Wiltshire"
  },
  {
    "text": "Worcestershire",
    "value": "Worcestershire",
    "key": "Worcestershire"
  }
]
