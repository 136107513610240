import React from "react";
import { Link } from "gatsby";
import { Container, Segment, Grid, Icon, Button } from "semantic-ui-react";

import footerStyles from "./footer.module.css";
import rocksteady16YrLogo from "../../images/rocksteady-16yr-logo.png";
import rocksteadyLogo from "../../images/rocksteady-logo-paint-it-black.png";
import wellbeingResourceLogo from "../../images/wellbeing-resource-black.png";
import fbLogo from "../../images/fb-logo.png";
import instagramLogo from "../../images/instagram-logo.png";
import linkedinLogo from "../../images/linkedin-logo.png";
import xLogo from "../../images/x-logo.png";
import {
  FACEBOOK_URL,
  INFO_EMAIL,
  INSTAGRAM_URL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_WITH_COUNTRY_CODE,
  TWITTER_URL,
  LINKEDIN_URL
} from "../../constants";

const PolicyLinks = () => (
  <ul className={footerStyles.footerUL}>
    <li>
      <a href="/privacy-notice/" className={footerStyles.linkMinimal}>
        Privacy Notice
      </a>
    </li>
    <li>
      <Link to="/cookie-policy/" className={footerStyles.linkMinimal}>
        Cookie Policy
      </Link>
    </li>
    <li>
      <Link to="/terms-conditions/" className={footerStyles.linkMinimal}>
        Website Terms &amp; Conditions
      </Link>
    </li>
    <li>
      {/* Safeguarding Policy is a PDF, but as filename can change, this is managed in _redirects  */}
      <a href="/safeguarding-policy/" className={footerStyles.linkMinimal} target="_blank">
        Safeguarding Policy
      </a>
    </li>
    <li>
      {/* Job Privacy Notice is a PDF, but as filename can change, this is managed in _redirects  */}
      <a href="/job-application-policy/" className={footerStyles.linkMinimal} target="_blank">
        Job Privacy Notice
      </a>
    </li>
  </ul>
);

const PrimaryLinks = () => {
  return (
    <ul className={footerStyles.footerUL}>
      <li>
        <Link to="/" className={footerStyles.linkMinimal}>
            Home
        </Link>
      </li>
      <li>
        <a
          href="https://backstage.rocksteadymusicschool.com/sign-in"
          className={footerStyles.linkMinimal}
          target="_blank"
          rel="noopener noreferrer"
        >
          Parent Login
        </a>
      </li>
      <li>
        <a
          href="https://portal.rocksteadymusicschool.com/"
          className={footerStyles.linkMinimal}
          target="_blank"
          rel="noopener noreferrer"
        >
          School Login
        </a>
      </li>
        <li>
          <a
            href="https://booking.rocksteadymusicschool.com/"
            className={footerStyles.linkMinimal}
            target="_blank"
            rel="noopener noreferrer"
          >
          Sign Up For Lessons
          </a>

        </li>
        <li>
          <Link to="/careers/" className={footerStyles.linkMinimal}>
            Careers
          </Link>
        </li>
        <li>
          <Link to="/contact/" className={footerStyles.linkMinimal}>
            Contact Us
          </Link>
        </li>
        <li>
          <Link to="/foundation/" className={footerStyles.linkMinimal}>
            Rocksteady Foundation
          </Link>
        </li>
    </ul>
  );
};

const SocialIcons = () => (
  <div className={footerStyles.socialIcon}>
      <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
          <img
              src={fbLogo}
              style={{ width: 25 }}
              alt='facebook'
          />
      </a>
      <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <img
              src={instagramLogo}
              style={{ width: 25 }}
              alt='instagram'
          />
      </a>
      <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
          <img
              src={xLogo}
              style={{ width: 25 }}
              alt='twitter'
          />
      </a>
      <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
          <img
              src={linkedinLogo}
              style={{ width: 25 }}
              alt='linkedin'
          />
      </a>
  </div>
);

const ContactLinks = () => (
  <div className={footerStyles.contactLinks}>
    <p className={footerStyles.contactInfo}>
        Rocksteady Music School, Bohunt Manor, Portsmouth Road, Liphook, Hampshire, GU30 7DL
    </p>
    <p className={footerStyles.contactInfo}>
        <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
        <br />
        <span className={footerStyles.phoneNumber}>
          <a href={`tel:${SUPPORT_PHONE_WITH_COUNTRY_CODE}`}>{SUPPORT_PHONE}</a>
        </span>
      <br />
      <span className={footerStyles.contactInfo}>
        (Calls are charged at local rate)
      </span>
    </p>
  </div>
);

const render16YrLogo = () => (
  <img
    src={rocksteady16YrLogo}
    alt="celebrating 16 years of rocksteady"
    style={{ width: 191 }}
  />
);

const renderRocksteadyLogo = () => (
    <img
        src={rocksteadyLogo}
        alt="rocksteady"
        style={{ width: 130 }}
    />
);

const renderWellbeingResource = () => (
    <img
        src={wellbeingResourceLogo}
        alt="wellbeing resource of the year"
        style={{ width: 300}}
    />
);

const SiteFooter = () => {
  return (
    <Segment vertical className={footerStyles.footer}>
      <Container>
        <Button icon circular href="#" className={footerStyles.toTopArrow}>
          <Icon name="arrow up" />
        </Button>
        <Grid padded>
          <Grid.Row className={footerStyles.topRow}>
            <Grid.Column computer={2} tablet={1} mobile={1} />
            <Grid.Column computer={3} tablet={3} mobile={7}>
              <div className={footerStyles.linkSecondary}>
                Quick Links
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={7}>
              <div className={footerStyles.linkSecondary}>
                Legal info
              </div>
            </Grid.Column>
            <Grid.Column computer={6} tablet={6} only="computer tablet">
              <div className={footerStyles.linkSecondary}>
                Contact
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={2} tablet={1} mobile={1}/>
            <Grid.Column
              computer={3}
              tablet={3}
              mobile={7}
              className={footerStyles.column}
            >
              <PrimaryLinks />
            </Grid.Column>
            <Grid.Column
              computer={3}
              tablet={3}
              mobile={7}
              className={footerStyles.column}
            >
              <PolicyLinks />
            </Grid.Column>
            <Grid.Column
              computer={6}
              tablet={6}
              className={footerStyles.column}
              only="computer tablet"
            >
                <ContactLinks />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only='mobile' className={footerStyles.topRow}>
            <Grid.Column mobile={1}  />
            <Grid.Column mobile={14}>
                <div className={footerStyles.linkSecondary}>
                    Contact
                </div>
            </Grid.Column>
        </Grid.Row>
          <Grid.Row only='mobile'>
            <Grid.Column mobile={1}  />
            <Grid.Column
                mobile={14}
                className={footerStyles.column}
            >
                <ContactLinks />
            </Grid.Column>
        </Grid.Row>
          <Grid.Row only='mobile'>
            <Grid.Column mobile={16} className={footerStyles.socialIcon}>
                {render16YrLogo()}
            </Grid.Column>
        </Grid.Row>
          <Grid.Row only='mobile'>
            <Grid.Column mobile={16} className={footerStyles.socialIcon}>
                {renderWellbeingResource()}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
              computer={1}
              tablet={1}
          />
          <Grid.Column
            computer={6}
            tablet={5}
            mobile={4}
          >
            <hr/>
          </Grid.Column>
          <Grid.Column className={footerStyles.socialBlock}
            computer={2}
            tablet={4}
            mobile={6}
          >
            <SocialIcons/>
          </Grid.Column>
          <Grid.Column
              computer={6}
              tablet={5}
              mobile={4}
          >
              <hr/>
          </Grid.Column>
        </Grid.Row>
          <Grid.Row only='mobile'>
                <Grid.Column mobile={16} className={footerStyles.socialIcon}>
                    {renderRocksteadyLogo()}
                </Grid.Column>
            </Grid.Row>
          <Grid.Row only='computer tablet' >
                <Grid.Column computer={2} tablet={2}/>
                <Grid.Column
                    computer={5}
                    tablet={5}
                >
                    {render16YrLogo()}
                </Grid.Column>
                <Grid.Column
                    computer={3}
                    tablet={3}
                >
                    {renderRocksteadyLogo()}
                </Grid.Column>
                <Grid.Column
                    computer={5}
                    tablet={5}
                >
                    {renderWellbeingResource()}
                </Grid.Column>
            </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className={footerStyles.copyright}>
                &copy; {new Date().getFullYear()} Rocksteady Music School
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default SiteFooter;
