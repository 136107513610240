export default class CookieUtils {
  static getCookies = () => {
    // This prevents Gatsby falling over during server side rendering
    if (typeof window === "undefined" || !window.document) {
      return {}
    }

    return Object.assign({}, ...document.cookie.split('; ').map(cookie => {
      const name = cookie.split('=')[0];
      const value = cookie.split('=')[1];

      return {[name]: value};
    }));
  }

  static analyticsConsentGiven = () => {
    const cookies = CookieUtils.getCookies();
    return cookies.analytics_consent === 'true';
  }

  static cookiePreferencesSet = () => {
    const cookies = CookieUtils.getCookies();
    return cookies.analytics_consent !== undefined;
  }

  static saveAnalyticsPreferences = (consent) => {
    if (!consent) {
      CookieUtils.removeAllCookies();
    }

    const expires = new Date(Date.now() + (86400*1000) * 180).toUTCString();
    CookieUtils.setCookie('analytics_consent', consent, expires);
  }

  static removeAllCookies = () => {
    const cookies = CookieUtils.getCookies();
    Object.entries(cookies).forEach(([key]) => {
      CookieUtils.deleteCookie(key);
    });
  }

  static setCookie = (key, value, expires) => {
    document.cookie = `${key}=${value};expires=${expires};path=/`;
  }

  static deleteCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
