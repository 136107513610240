import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { Image } from "semantic-ui-react";
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import CookieUtils from "../../utils/cookie-utils"

function SEO({ description, lang, meta, keywords, title, indexing, canonical }) {

  // We want to update the GA permissions per-user at runtime,
  // rather than bake them in at build time
  const [analyticsConsentState, setAnalyticsConsentState] = useState(false);
  useEffect(() => {
    setAnalyticsConsentState(CookieUtils.analyticsConsentGiven());
  }, []);

  return (
    <>
      <StaticQuery
        query={detailsQuery}
        render={data => {
          const metaDescription =
            description || data.site.siteMetadata.description
          return (
            <Helmet
              htmlAttributes={{
                lang
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              link={
                canonical
                  ? [{ rel: 'canonical', key: canonical, href: canonical }]
                  : []
              }
              meta={[
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
                {
                  name: `robots`,
                  content: indexing,
                },
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                      }
                    : []
                )
                .concat(meta)}
            >
              {/* Remove Plausible once GA is fully embedded */}
              <script async defer data-domain="rocksteadymusicschool.com" src="https://plausible.io/js/plausible.js"></script>
              <script async defer data-domain="rocksteadymusicschool.com" src="https://plausible.io/js/plausible.outbound-links.js"></script>
              <script>{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}</script>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-S0Z8LQTWYD"></script>
              <script>
                {   analyticsConsentState &&
                    `window.dataLayer = window.dataLayer || [];
                     function gtag(){dataLayer.push(arguments);}
                     gtag('js', new Date());
                     gtag('config', 'G-S0Z8LQTWYD');`
                }
              </script>
              {/* <!-- Google Tag Manager --> */}
              <script>
                { analyticsConsentState && `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);})
                    (window,document,'script','dataLayer','GTM-T637PFJ');
                  `
                }
              </script>
              {/* <!-- End Google Tag Manager --> */}


              {/* <!-- Facebook Pixel Code -->  */}
              <script>
                {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window,document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');

                  // https://developers.facebook.com/docs/meta-pixel/implementation/gdpr
                  ${analyticsConsentState ? "" : "fbq('consent', 'revoke');"}

                  fbq('init', '1124404561843430');
                  fbq('track', 'PageView');

                  ${analyticsConsentState ? "fbq('consent', 'grant');" : ""}
                `}
              </script>

              {/* <!-- Twitter Pixel Code -->  */}
              <script>
                { analyticsConsentState &&
                  `
                    !function(e,t,n,s,u,a)
                    {e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','ocxbn');
                  `
                }
              </script>

              {/* <!-- TikTok Pixel Code -->  */}
              <script>
                { analyticsConsentState &&
                  `
                    !function (w, d, t)
                    {
                      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};  ttq.load('CDV0LBRC77UF6OITNAJG');  ttq.page();
                    }(window, document, 'ttq');
                  `
                }
              </script>
              {/* LinkedIn Insight Tag */}
              <script type="text/javascript">
                {
                  analyticsConsentState &&
                  `
                  _linkedin_partner_id = "3749209";
                   window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                   window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                  `
                }
              </script>
              <script type="text/javascript">
                {
                  analyticsConsentState &&
                  `
                  (function(l) {
                  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                  window.lintrk.q=[]}
                  var s = document.getElementsByTagName("script")[0];
                  var b = document.createElement("script");
                  b.type = "text/javascript";b.async = true;
                  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                  s.parentNode.insertBefore(b, s);
                  }
                  )(window.lintrk);
                  `
                }
              </script>
            </Helmet>
          )
        }}
      />
      {
        analyticsConsentState &&
        <noscript>
          <Image height="1" width="1" alt="" style={{ display: 'none' }} src="https://px.ads.linkedin.com/collect/?pid=3749209&fmt=gif" />
        </noscript>
      }
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  indexing: "all"
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  indexing: PropTypes.string,
  canonical: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
